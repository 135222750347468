import { P1 } from "../global/Text";

export default function About({ data }) {
  const { title, sections } = data;

  return (
    <div className="pt-[14vw] md:pt-[8vw] pb-[14vw] md:pb-[7vw] grid grid-cols-12 gap-[1.3vw] md:border-b-[0.5px] border-[#fff] mix-blend-difference">
      <div className="col-span-2">
        {title && (
          <P1 className="sticky top-[80px] md:top-[20px] mt-[4px] md:mt-0">
            {title}
          </P1>
        )}
      </div>
      <div className="col-start-5 md:col-start-7 xl:col-start-9 col-span-8 md:col-span-6 xl:col-span-4 flex flex-col gap-[10vw] md:gap-[6.25vw]">
        {sections.map((section) => (
          <div
            className="grid grid-cols-8 md:grid-cols-2 gap-[1.3vw] pt-[1vw] border-t-[0.5px] border-[#fff] mix-blend-difference"
            key={section.name}
          >
            <P1 as="h3" className="col-span-3 md:col-span-1">
              {section.name}
            </P1>
            <div className="col-span-5 md:col-span-1 flex flex-col gap-[1vw] pr-[2.5vw]">
              {section.content.map((item, itemIndex) => (
                <P1 key={`${section.name}-${itemIndex}`}>{item}</P1>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
